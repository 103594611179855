import {
  Card,
  MarketingLayout,
  Button,
  Image,
  Box,
  Tooltip,
  Accordion,
  accordionItemBuilder,
  TextButton,
  Modal,
  CustomModalLayout,
  Layout,
  Cell,
  Heading,
  CopyClipboard,
  Input,
  EmptyState,
  Loader,
  WixDesignSystemProvider,
  Search,
  Divider,
  Badge,
  Text,
  FormField,
} from "@wix/design-system";
import * as React from "react";
import "@wix/design-system/styles.global.css";
import { useEffect, useState } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import { ReactTagManager } from "react-gtm-ts";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);

  const [offers, setOffers] = useState<any[]>([]);
  const [state, setState] = useState<any>("pending");
  const [keyword, setKeyword] = useState<string>("");
  const [openedOfferId, setOpenedOfferId] = useState<string | null>(null);
  const appId = "offers-app";
  const siteId = "";

  const getData = async () => {
    if (appId) {
      setState("pending");
      await fetch(
        `https://certifiedcode.wixsite.com/custom-dashboard/_functions/offers/${appId}`,
        {
          headers: {
            Authorization: new URLSearchParams(window.location.search).get(
              "instance"
            )!,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setOffers(data);
          setState("success");
        })
        .catch(() => {
          setState("error");
        });
    } else {
      setState("error");
    }
  };
  useEffect(() => {
    getData();
  }, [appId]);

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Card hideOverflow>
        <Modal isOpen={!!openedOfferId}>
          <CustomModalLayout
            title={`Offers from ${
              offers.find((v) => v._id === openedOfferId)?.app.details.name
            }`}
            onCloseButtonClick={() => {
              setOpenedOfferId(null);
            }}
            // onHelpButtonClick={() => {}}
            width="600px"
            content={
              <Layout>
                <Cell>
                  <Text>
                    {offers.find((v) => v._id === openedOfferId)?.title}
                  </Text>
                </Cell>
                <Cell span={12}>
                  <Heading size="medium" as="h2">
                    Step 1: Install{" "}
                    {
                      offers.find((v) => v._id === openedOfferId)?.app.details
                        .name
                    }
                  </Heading>
                </Cell>
                <Cell span={12}>
                  <Button
                    as="a"
                    href={`https://go.certifiedcode.global/get/${
                      offers.find((v) => v._id === openedOfferId)?.app.details
                        .appId
                    }${siteId ? `/${siteId}` : ""}`}
                    target="_blank"
                  >
                    Install App
                  </Button>
                </Cell>
                <Cell span={12}>
                  <Heading size="medium" as="h2">
                    Step 2: Apply coupon code
                  </Heading>
                </Cell>
                <Cell span={12}>
                  <Box gap="SP2" verticalAlign="middle">
                    <CopyClipboard
                      value={offers.find((v) => v._id === openedOfferId)?.code}
                      resetTimeout={1500}
                    >
                      {({ isCopied, copyToClipboard }) => (
                        <Input
                          readOnly
                          value={
                            offers.find((v) => v._id === openedOfferId)?.code
                          }
                          suffix={
                            <Box verticalAlign="middle" marginRight="SP1">
                              <TextButton
                                onClick={() => copyToClipboard()}
                                size="small"
                                prefixIcon={<Icons.DuplicateSmall />}
                              >
                                {!isCopied ? "Copy" : "Copied!"}
                              </TextButton>
                            </Box>
                          }
                        />
                      )}
                    </CopyClipboard>
                    <Text size="small" skin="disabled">
                      or
                    </Text>
                    <Button
                      as="a"
                      href={`https://www.wix.com/apps/upgrade/${
                        offers.find((v) => v._id === openedOfferId)?.app.details
                          .appId
                      }${siteId ? `?metaSiteId=${siteId}` : ""}`}
                      target="_blank"
                    >
                      Go to Checkout
                    </Button>
                  </Box>
                </Cell>
                <Cell span={12}>
                  <Heading size="medium" as="h2">
                    Step 3: Enjoy your new Premium app 🎉
                  </Heading>
                </Cell>
                <Cell span={12}>
                  <Button
                    skin="destructive"
                    priority="secondary"
                    size="tiny"
                    onClick={() => {
                      // @ts-ignore
                      window.Intercom(
                        "showNewMessage",
                        "I have a problem with the offer: " +
                          offers.find((v) => v._id === openedOfferId)?.title
                      );
                    }}
                  >
                    Report a problem
                  </Button>
                </Cell>
                <Cell span={12}></Cell>
              </Layout>
            }
          ></CustomModalLayout>
        </Modal>
        <Card.Header
          title="Offers for you"
          subtitle="Thank you for choosing Certified Code."
          suffix={
            <Search
              placeholder="Search for offers"
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              expandable
            />
          }
        />
        <Card.Divider />
        {state === "pending" && offers.length === 0 && (
          <EmptyState
            image={<Loader size="large" />}
            theme="page-no-border"
            subtitle="Searching available offers for you"
          ></EmptyState>
        )}
        {state === "error" && offers.length === 0 && (
          <EmptyState
            theme="page-no-border"
            title="We couldn't load this page"
            subtitle="Looks like there was a technical issue on our end. Wait a few minutes and try again."
          >
            <TextButton
              onClick={() => {
                getData();
              }}
              prefixIcon={<Icons.Refresh />}
            >
              Try Again
            </TextButton>
          </EmptyState>
        )}
        {state === "success" && offers.length === 0 && (
          <EmptyState
            theme="page-no-border"
            title="You've all caught up!"
            subtitle="You don't have any offers right now. Check back later to see if you have any new offers."
          >
            {
              <TextButton
                as="a"
                href={`https://www.wix.com/app-market/developer/certified-code`}
                target="_blank"
                prefixIcon={<Icons.AppMarket />}
              >
                More Apps
              </TextButton>
            }
          </EmptyState>
        )}
        <Card>
          <MarketingLayout
            size="small"
            badge={<Badge skin="urgent">ELEVATED</Badge>}
            title="Get a $100 Visa card for free. Terms apply."
            description={
              <Text>
                Join thousands of other startups worldwide using Notion. You'll
                get a <strong>$100 Visa card</strong> plus 3 months of the
                Notion Plus plan with unlimited AI—completely free. Type{" "}
                <code>REFERRAL#7603498</code> as your referral partner from the
                drop-down list, and enter the unique code{" "}
                <code>KEY7603498</code> at the end of the form.
              </Text>
            }
            actions={
              <Box direction="vertical" gap="SP2">
                <CopyClipboard value={"REFERRAL#7603498"} resetTimeout={1500}>
                  {({ isCopied, copyToClipboard }) => (
                    <FormField label="Referral Partner">
                      <Input
                      readOnly
                      value={"REFERRAL#7603498"}
                      suffix={
                        <Box verticalAlign="middle" marginRight="SP1">
                          <TextButton
                            onClick={() => copyToClipboard()}
                            size="small"
                            prefixIcon={<Icons.DuplicateSmall />}
                          >
                            {!isCopied ? "Copy" : "Copied!"}
                          </TextButton>
                        </Box>
                      }
                    />
                    </FormField>
                  )}
                </CopyClipboard>
                <CopyClipboard value={"KEY7603498"} resetTimeout={1500}>
                  {({ isCopied, copyToClipboard }) => (
                    <FormField label="Unique Code">
                      <Input
                      readOnly
                      value={"KEY7603498"}
                      suffix={
                        <Box verticalAlign="middle" marginRight="SP1">
                          <TextButton
                            onClick={() => copyToClipboard()}
                            size="small"
                            prefixIcon={<Icons.DuplicateSmall />}
                          >
                            {!isCopied ? "Copy" : "Copied!"}
                          </TextButton>
                        </Box>
                      }
                    />
                    </FormField>
                  )}
                </CopyClipboard>
                <Button
                  skin="ai"
                  suffixIcon={<Icons.ExternalLink />}
                  href="https://www.notion.so/startups-apply"
                  target="_blank"
                  as="a"
                >
                  Apply Now
                </Button>
                <Box direction="horizontal" gap={"SP1"}>
                  <Icons.LockLockedSmall color="rgba(0, 6, 36, 0.3)" />
                  <Text size="small" skin="disabled">
                    on notion.so secured site. Apply by Nov 1, 2024.
                  </Text>
                </Box>
              </Box>
            }
            image={
              <Image
                height="200px"
                transparent
                src="https://www.notion.so/images/notion-for-startups.png"
              />
            }
          />
        </Card>
        <Divider />
        <Accordion
          items={offers
            .filter((v) => {
              if (keyword === "") {
                return true;
              }
              return (
                v.app.details.name
                  .toLowerCase()
                  .includes(keyword.toLowerCase()) ||
                v.title.toLowerCase().includes(keyword.toLowerCase())
              );
            })
            .map((v) => {
              return accordionItemBuilder({
                disabled: false,
                title: v.app.details.name,
                icon: (
                  <Image
                    src={v.app.details.icon}
                    fit="contain"
                    transparent
                    borderRadius={"4px"}
                  />
                ),
                subtitle: v.title,
                buttonType: "button",
                expandLabel: "Get Offer",
                onToggle: () => {
                  console.log(v);
                  if (openedOfferId === v._id) {
                    setOpenedOfferId(null);
                  } else {
                    setOpenedOfferId(v._id);
                  }
                },
              });
            })}
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
